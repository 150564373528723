import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {useRouteMatch} from 'react-router-dom';
import {unmarkChanges} from '../../common/action/pageLeaveActions';
import ConfirmCard from '../../common/component/ConfirmCard';
import {DefaultState} from '../../common/reducer/reducers';
import {showMessage} from '../../message/action/messageActions';
import {updateAccount} from '../../user/action/userActions';
import userApi from '../../user/api/userApi';
import {setOnboarding} from '../action/onboardingActions';

interface MatchParams {
    stage: string;
}

const OnboardingConfirmation = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch<MatchParams>();
    const stage = match.params.stage;
    const isSepa = stage === 'sepa';
    const queryParams = new URLSearchParams(location.search);
    const paymentAmount = queryParams.get('amount');
    const children = useSelector((state: DefaultState) => state?.user?.account?.children);

    const finishSave = () => {
        userApi.findMe().then(res => {
            const child = children && children.length > 0 ? children[0] : null;
            const childBefore = child?.id && children ? children.find(c => c.id === child?.id) : null;
            const welfareBefore = childBefore && childBefore.childWelfare && childBefore.childWelfare.length > 0;

            if (res.data.success && res.data.result.account) {
                dispatch(updateAccount(res.data.result.account));
            }
            dispatch(unmarkChanges());
            dispatch(showMessage('Child.SAVE_SUCCESS', {child: `${child?.firstName} ${child?.lastName}`}));

            if (!welfareBefore && child?.childWelfare && child.childWelfare.length > 0) {
                dispatch(showMessage('ChildSettings.WELFARE_CONFIRMATION_INFO'));
            }
        });
    }

    const renderTitle = useMemo(() => {
        const title = t('Onboarding.COMPLETED');

        if (isSepa) {
            return t('Finance.DIRECT_DEBIT_COMPLETED');
        } else if (paymentAmount) {
            return `${t('Finance.PAYMENT_METHODS_COMPLETED', {paymentAmount: paymentAmount})} ${title}`;
        } else {
            return title;
        }
    }, [t, isSepa, paymentAmount]);

    const handelNext = () => {
        const nextStage = isSepa ? '/finances/one-time-payment-ask' : '/';
        if (!isSepa) {
            dispatch(setOnboarding(false));
        }
        finishSave();
        history.push(nextStage);
    };

    return (
        <div className="container finance">
            <div className="center">
                <ConfirmCard title={renderTitle}
                             description={t('Onboarding.COMPLETED_DESCRIPTION')}
                             primaryButtonTitle={t('Onboarding.LETS_GO')}
                             onClickPrimary={handelNext}
                />

            </div>
        </div>
    );
};

export default OnboardingConfirmation;
