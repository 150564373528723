import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import groupApi from '../../administration/api/groupApi';
import transactionApi from '../../administration/api/transactionApi';
import {markChanges} from '../../common/action/pageLeaveActions';
import ConfirmationDialog from '../../common/component/form/ConfirmationDialog';
import TextInput from '../../common/component/form/TextInput';
import TextOutput from '../../common/component/TextOutput';
import CloseIcon from '../../common/icons/CloseIcon';
import DeleteIcon from '../../common/icons/DeleteIcon';
import '../style/GroupInput.scss';
import {showMessage} from '../../message/action/messageActions';
import {Group} from '../../types/institution/Group';
import {updateAccount} from '../../user/action/userActions';
import userApi from '../../user/api/userApi';
import childApi from '../api/ChildApi';

type Props = {
    group?: Group,
    groupCode?: string,
    isValid: boolean,
    showError?: boolean,
    onChangeGroup: (group: Group | null, selectedGroupCode: string | null, treatAsUnsavedChange: boolean) => void,
    childName: string,
    childId?: number,
    catererId: number,
    required?: boolean
}

function GroupInput({
                        group,
                        groupCode,
                        isValid,
                        showError = false,
                        onChangeGroup,
                        childName,
                        childId,
                        catererId
                    } : Readonly<Props>) {
    const [t] = useTranslation();
    const [selectedGroupCode, setSelectedGroupCode] = useState<string | undefined>(group ? group.code : groupCode);
    const [showLoadError, setShowLoadError] = useState(false);
    const [showInput, setShowInput] = useState(!group);
    const [originalGroup, setOriginalGroup] = useState<Group>();
    const [hasGroupCodeChange, setHasGroupCodeChange] = useState(false);
    const [showGroupEditConfirmation, setShowGroupEditConfirmation] = useState(false);
    const [showGroupRemovalConfirmation, setShowGroupRemovalConfirmation] = useState(false);

    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const dispatch = useDispatch();

    const loadGroup = useCallback(() => {
        if (!selectedGroupCode) {
            onChangeGroup(null, null, true);
            return;
        }

        setShowLoadError(false);
        groupApi.getGroupByCode(selectedGroupCode).then((response) => {
            const result = response.data.result as Group;
            if (response.data.success && result !== null) {
                if (result.catererId !== catererId) {
                    setShowLoadError(true);
                    setShowInput(true);
                } else {
                    setShowLoadError(false);
                    setShowInput(false);
                    onChangeGroup(result, selectedGroupCode, true);
                    setHasGroupCodeChange(false);
                }
            } else {
                setShowLoadError(true);
                // Pass the group code input up for validation:
                onChangeGroup(null, selectedGroupCode, true);
            }
        });
    }, [onChangeGroup, selectedGroupCode, catererId]);

    useEffect(() => {
        // Delay request after input
        if (selectedGroupCode && hasGroupCodeChange) {
            timeoutIdRef.current = setTimeout(loadGroup, 1000);
        }

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [selectedGroupCode, hasGroupCodeChange, loadGroup]);

    const changeGroupInput = useCallback((value : string) => {
        setSelectedGroupCode(value.trim());
        dispatch(markChanges());
        setHasGroupCodeChange(true);
    }, [dispatch]);

    function switchToEditMode() {
        if (group) {
            setOriginalGroup({...group});
        }
        setShowInput(true);
    }

    const cancel = useCallback(() => {
        setShowInput(!group && !originalGroup);
        setHasGroupCodeChange(false);
        setShowLoadError(false);
        if (group) {
            setSelectedGroupCode(group.code);
            onChangeGroup(group, group.code, false);
        } else if (originalGroup) {
            setSelectedGroupCode(originalGroup.code);
            onChangeGroup(originalGroup, originalGroup.code, false);
        } else {
            setSelectedGroupCode(undefined);
            onChangeGroup(null, null, true);
        }
    }, [group, originalGroup, onChangeGroup]);

    const handleChangeGroup = useCallback(() => {
        if (childId) {
            transactionApi.existsCancelableOrderOrSubscription(childId).then(res => {
                if (res.data.success) {
                    if (res.data.result) {
                        setShowGroupEditConfirmation(true);
                    } else {
                        switchToEditMode();
                    }
                }
            });
        } else {
            switchToEditMode();
        }
    }, [childId]);

    const handleRemoveGroup = useCallback(() => {
        if (group) {
            setShowGroupRemovalConfirmation(true);
        }
    }, [group]);

    return (
        <>
            <div className="group-input">
                {
                    /* Edit form */
                    showInput &&
                    <div className="row align-items-start">
                        <div className="col-md-6 col-12">
                            <TextInput
                                label={t('Child.GROUP_CODE')}
                                value={selectedGroupCode}
                                isValid={!showLoadError && isValid}
                                errorMessage={showLoadError ? t('Error.INVALID_INSTITUTION') : undefined}
                                showError={showLoadError || showError}
                                onChange={changeGroupInput}
                            />
                        </div>
                        {
                            childId && (hasGroupCodeChange || group) &&
                            <div className="col-3">
                                <button
                                    className="btn btn-transparent btn-sm mt-4"
                                    onClick={cancel}
                                >
                                    <CloseIcon/>
                                    <span className="ml-2">{t('Button.CANCEL')}</span>
                                </button>
                            </div>
                        }
                    </div>
                }
                {
                    /* Display group */
                    !showInput && group &&
                    <div>
                        <div className="d-inline-block mr-5">
                            <TextOutput label={t('Institution.GROUP')} text={group.name}>
                                <div className="small text-muted">{group.institutionName}</div>
                            </TextOutput>
                        </div>
                        <div className="d-inline-block">
                            <button
                                onClick={handleChangeGroup}
                                className="btn btn-secondary btn-sm mr-2 mb-2"
                            >
                                {t('Child.CHANGE_GROUP')}
                            </button>
                            {
                                childId &&
                                <button
                                    onClick={handleRemoveGroup}
                                    className="btn btn-transparent btn-sm mb-2"
                                >
                                    <div className="d-none d-md-block mr-2"><DeleteIcon size={0.85}/></div>
                                    {t('Child.REMOVE_FROM_GROUP')}
                                </button>
                            }
                        </div>
                    </div>
                }
            </div>
            <ConfirmationDialog
                open={showGroupEditConfirmation}
                title={t('Group.CONFIRM_DIALOG_HEADER')}
                body={t('Group.CONFIRM_DIALOG_BODY', {child: childName})}
                confirmLabel={t('Button.CONTINUE')}
                cancelLabel={t('Button.CANCEL')}
                onConfirm={() => {
                    switchToEditMode();
                    setShowGroupEditConfirmation(false);
                }}
                onCancel={() => setShowGroupEditConfirmation(false)}
            />
            {
                group &&
                <ConfirmationDialog
                    open={showGroupRemovalConfirmation}
                    title={t('Common.CONFIRM_REMOVAL_TITLE')}
                    body={t('Child.CONFIRM_GROUP_REMOVAL_BODY', {child: childName, group: group.name})}
                    confirmLabel={t('Button.REMOVE')}
                    cancelLabel={t('Button.CANCEL')}
                    onConfirm={() => {
                        childApi.removeChildFromGroup(childId).then(response => {
                            if (response.data.success) {
                                userApi.findMe().then(userResponse => {
                                    if (userResponse.data.success) {
                                        dispatch(updateAccount(userResponse.data.result.account));
                                    }
                                });
                                dispatch(showMessage('Common.SAVE_SUCCESS'));
                                setShowInput(true);
                                setSelectedGroupCode(undefined);
                                onChangeGroup(null, null, false);
                            }
                        });
                        setShowGroupRemovalConfirmation(false);
                    }}
                    onCancel={() => setShowGroupRemovalConfirmation(false)}
                />
            }
        </>
    );
}

export default GroupInput;
