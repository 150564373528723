import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {unmarkChanges} from '../../../common/action/pageLeaveActions';
import ConfirmCard from '../../../common/component/ConfirmCard';
import PageLeaveGuard from '../../../common/component/PageLeaveGuard';
import CloseIcon from '../../../common/icons/CloseIcon';
import {DefaultState} from '../../../common/reducer/reducers';
import mealPlanManagementApi from '../../../mealplan/api/mealPlanManagementApi';
import {Child} from '../../../types/Child';
import {ChildWelfare} from '../../../types/child/ChildWelfare';
import {MenuGroup} from '../../../types/mealplan/MenuGroup';
import ChildSetupBasics from './ChildSetupBasics';
import ChildSetupSpecialDiet from './ChildSetupSpecialDiet';
import ChildSetupSubscriptionWrapper from './ChildSetupSubscriptionWrapper';
import ChildSetupWelfare from './ChildSetupWelfare';

type Props = {
    catererId: number,
    accountId: number,
    onSaveDone: (child: Child | undefined) => void,
    onCancel: (child: Child | undefined) => void,
    isOnboarding?: boolean,
    isSupport?: boolean,
}

function ChildSetup({catererId, accountId, onSaveDone, onCancel, isOnboarding, isSupport}: Readonly<Props>) {
    const [t] = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const hasChanges = useSelector((state: DefaultState) => state.pageLeave.hasUnsavedChanges);

    const [child, setChild] = useState<Child>();
    const [nextStage, setNextStage] = useState('basics');
    const [specialMenuGroupsOpen, setSpecialMenuGroupsOpen] = useState<Array<MenuGroup>>([]);
    const [specialMenuGroupsAttest, setSpecialMenuGroupsAttest] = useState<Array<MenuGroup>>([]);

    const handleProceedFromBasics = useCallback((child: Child) => {
        setChild(child);
        mealPlanManagementApi.fetchMenuGroupsForInstitution(child.group.institutionId, false, false, true).then(response => {
            if (response.data.success) {
                const menuGroups: Array<MenuGroup> = response.data.result;

                const openMenuGroupsTemp: Array<MenuGroup> = [];
                menuGroups
                    .filter(menuGroup => menuGroup.nutritionClass && !menuGroup.nutritionClass.isAttestRequired)
                    .forEach(menuGroup => openMenuGroupsTemp.push(menuGroup));
                setSpecialMenuGroupsOpen(openMenuGroupsTemp);

                const attestMenuGroupsTemp: Array<MenuGroup> = [];
                menuGroups
                    .filter(menuGroup => menuGroup.nutritionClass?.isAttestRequired)
                    .forEach(menuGroup => attestMenuGroupsTemp.push(menuGroup));
                setSpecialMenuGroupsAttest(attestMenuGroupsTemp);

                if (openMenuGroupsTemp.length > 0 || attestMenuGroupsTemp.length > 0) {
                    setNextStage('special-meal');
                } else {
                    setNextStage('subscription');
                }
            }
        });
    }, []);

    const handleProceedFromSpecialMeal = useCallback((menuGroup: MenuGroup | null) => {
        if (menuGroup?.nutritionClass.isAttestRequired) {
            setNextStage('welfare');
        } else {
            setNextStage('subscription');
        }
    }, []);

    const handleCancel = useCallback(() => {
        onCancel(child);
    }, [onCancel, child]);

    const handleProceedFromWelfare = (updatedChildWelfare: ChildWelfare | undefined = undefined) => {
        if (!updatedChildWelfare) {
            dispatch(unmarkChanges());
        } else {
            setChild((prevChild) => {
                if (prevChild) {
                    return {
                        ...prevChild,
                        childWelfare: [...prevChild.childWelfare, updatedChildWelfare]
                    };
                }
                return prevChild;
            });
        }

        setNextStage('done')
    }

    const handleProceedFromDone = () => {
        if (isOnboarding) {
            if (!!child?.childWelfare && child.childWelfare.length > 0) {
                history.push('/onboarding-done')
            } else {
                history.push('/finances/sepa-mandate');
            }
        } else if (isSupport) {
            onSaveDone(undefined);
        } else {
            history.push('/overview');
        }
    };

    return <>
        <h1>
            <div className="d-flex justify-content-between">
                {isOnboarding ? t('Child.SETUP') : t('Parent.ADD_CHILD')}
                <button className="btn btn-transparent" onClick={handleCancel}>
                    <CloseIcon/>
                    <span className="ml-2">{t('Button.CLOSE')}</span>
                </button>
            </div>
        </h1>
        {
            nextStage === 'basics' &&
            <ChildSetupBasics
                catererId={catererId}
                accountId={accountId}
                onProceed={handleProceedFromBasics}
                onSaveDone={onSaveDone}
            />
        }

        {
            nextStage === 'special-meal' && child &&
            <ChildSetupSpecialDiet
                accountId={accountId}
                child={child}
                specialMenuGroupsOpen={specialMenuGroupsOpen}
                specialMenuGroupsAttest={specialMenuGroupsAttest}
                onProceed={handleProceedFromSpecialMeal}
            />
        }

        {
            nextStage === 'subscription' && child &&
            <ChildSetupSubscriptionWrapper
                catererId={catererId}
                accountId={accountId}
                child={child}
                onProceed={() => setNextStage('welfare')}
                onSkip={() => {dispatch(unmarkChanges()); setNextStage('welfare');} }
            />
        }

        {
            nextStage === 'welfare' && child &&
            <ChildSetupWelfare child={child}
                               onProceed={(welfare) => handleProceedFromWelfare(welfare)}
                               onSkip={handleProceedFromWelfare}
            />
        }

        {
            nextStage === 'done' && child &&
            <ConfirmCard title={t('ChildSetup.PROFILE_COMPLETE', {name: child.firstName})}
                                      description={t('ChildSetup.WANT_TO_ADD')}
                                      primaryButtonTitle={t('Button.YES')}
                                      onClickPrimary={() => setNextStage('basics')}
                                      secondaryButtonTitle={t('Button.NO')}
                                      onClickSecondary={handleProceedFromDone}
            />
        }
        <PageLeaveGuard hasChanges={hasChanges}/>
    </>;
}

export default ChildSetup;
